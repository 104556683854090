import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import AbstractDetailTable from "../components/part/abstractDetailTable"
import AbstractDetailReview from "../components/part/abstractDetailReview"
import AbstractDetailModal from "../components/part/abstractDetailModal"

const AbstractDetail = () => {
  return (
    <Layout>
      <SEO title="Submission" />
      <Banner title="Abstract Details"></Banner>
      <div class="content-wrap" style={{ marginTop: "32px" }}>
        <div class="container clearfix">
          <div class="row">
            <AbstractDetailTable></AbstractDetailTable>
            <AbstractDetailReview></AbstractDetailReview>
          </div>
          <AbstractDetailModal></AbstractDetailModal>
        </div>
      </div>
    </Layout>
  )
}

export default AbstractDetail
