import React from "react"

const AbstractDetailReview = () => {
  const badgeStyle = { position: "relative", transform: "translateY(22%)" }
  return (
    <div className="col-md-4 pd-reg">
      <div className="fancy-title top-md title-border">
        <h4>Result</h4>
      </div>
      <h5 className="mb-0">STATUS</h5>
      <span className="badge badge-result badge-danger" style={badgeStyle}>
        Under Reviewed
      </span>
      <h5 className="mb-0">FEEDBACK</h5>
      <span className="font-italic text-secondary">
        Your abstract under reviewing by the our reviewer.
      </span>
    </div>
  )
}

export default AbstractDetailReview
