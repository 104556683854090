import React from "react"
import { connect } from "react-redux"

const AbstractDetailModal = () => {
  return (
    <div>
      <div className="clear" />
      {/* Modal Submit */}
      <div className="modal1 mfp-hide" id="submitreview">
        <div
          className="block mx-auto"
          style={{ backgroundColor: "#FFF", maxWidth: "500px" }}
        >
          <div className="center" style={{ padding: "50px" }}>
            <h3>Submit Review</h3>
            <p className="mb-0">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nostrum
              delectus, tenetur obcaecati porro! Expedita nostrum tempora quia
              provident perspiciatis inventore, autem eaque, quod explicabo,
              ipsum, facilis aliquid! Sapiente, possimus quo!
            </p>
          </div>
          <div className="section center m-0" style={{ padding: "30px" }}>
            <button className="button button-circle button-border button-white">
              Cancel
            </button>
            <button
              className="button button-3d button-circle gradient-grey-orange mr-0"
              style={{ padding: "10px 22px" }}
            >
              Submit Review
            </button>
          </div>
        </div>
      </div>
      {/* Modal Log Out */}
      <div className="modal1 mfp-hide" id="logout">
        <div
          className="block mx-auto"
          style={{ backgroundColor: "#FFF", maxWidth: "500px" }}
        >
          <div className="center" style={{ padding: "50px" }}>
            <h3>Log Out</h3>
            <p className="mb-0">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nostrum
              delectus, tenetur obcaecati porro! Expedita nostrum tempora quia
              provident perspiciatis inventore, autem eaque, quod explicabo,
              ipsum, facilis aliquid! Sapiente, possimus quo!
            </p>
          </div>
          <div className="section center m-0" style={{ padding: "30px" }}>
            <button
              className="button button-3d button-circle gradient-grey-orange mr-0"
              style={{ padding: "10px 22px" }}
            >
              Cancel
            </button>
            <button className="button button-circle button-border button-white">
              Log Out
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect()(AbstractDetailModal)
