import React from "react"
import { navigate } from "gatsby"
import { PROFILE } from "../../constants/routes"
import { connect } from "react-redux"

const AbstractDetailTable = ({ state }) => {
  const { detail } = state.file
  return (
    <div className="col-md-8">
      <div className="row clearfix">
        <div className="col-lg-12">
          <div className="clearfix" id="abstract">
            <div className="row mb-1">
              <div className="col-md-6 mb-4">
                <button
                  onClick={() => navigate(PROFILE)}
                  className="button button-reg button-3d button-circle gradient-grey-orange mr-0 text-center mb-3 submit float-left"
                >
                  Back to Profile
                </button>
              </div>
              {/* <div class="col-md-6">
                  <h3 class="float-right vertical-center mb-6"> Abstract Details </h3>
                </div> */}
            </div>
            <table className="table table-bordered mb-5">
              <tbody>
                <tr>
                  <th className="w-25">Title</th>
                  <td className="w-75">{detail.title}</td>
                </tr>
                <tr>
                  <th className="w-25">Author</th>
                  <td className="w-75">{detail.author}</td>
                </tr>
                <tr>
                  <th className="w-25">Author's Affiliation</th>
                  <td className="w-75">
                    <ol className="ml-3 mb-1">
                      {detail.author_affiliation.split("| ").map((a, i) => (
                        <li key={i}>{a}</li>
                      ))}
                    </ol>
                  </td>
                </tr>
                <tr>
                  <th className="w-25">Category</th>
                  <td className="w-75">{detail.category}</td>
                </tr>
                <tr>
                  <th className="w-25">Background</th>
                  <td className="w-75">{detail.background}</td>
                </tr>
                <tr>
                  <th className="w-25">Methods</th>
                  <td className="w-75">{detail.methods}</td>
                </tr>
                <tr>
                  <th className="w-25">Result</th>
                  <td className="w-75">{detail.result}</td>
                </tr>
                <tr>
                  <th className="w-25">Conclusion</th>
                  <td className="w-75">{detail.conclusion}</td>
                </tr>
                <tr>
                  <th className="w-25">Keywords</th>
                  <td className="w-75">{detail.keywords}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(state => ({ state }))(AbstractDetailTable)
